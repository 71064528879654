import * as React from 'react';
import { RootState } from 'redux/rootReducer';
import { fetchMetrics } from 'redux/ducks/dashboard';
import { connect } from 'react-redux';
import Dashboard from 'pages/Dashboard/Dashboard';
import { getDashboardMetrics } from 'redux/ducks/dashboard/selectors';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type DashboardContainerProps = StateProps & DispatchProps;

const DashboardContainer: React.FunctionComponent<DashboardContainerProps> = (props) => {
  const {
    state,
    metrics,
    vacation,
    accountId,
    timeRange,
    period,
    fetchMetrics,
    saleAdded,
    isMenuOpen
  } = props;

  React.useEffect(() => {
    void fetchMetrics({ customerIds: [accountId], timeRange, period });
  }, [accountId, period, timeRange.from, timeRange.to, saleAdded]);

  return (
    <Dashboard
      isMenuOpen={isMenuOpen}
      vacation={vacation}
      metrics={metrics}
      isLoading={state !== 'idle'}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.user.accountId,
  timeRange: state.reportFilter.timeRange,
  period: state.reportFilter.period,
  metrics: getDashboardMetrics(state),
  vacation: state.dashboard.vacation,
  state: state.dashboard.state,
  // old guest registrations use sale data, need to refetch data
  // if guests in sale is added
  saleAdded: state.settings.enableGuestRegistrationFlow ? undefined : state.sales.lastSale,
  isMenuOpen: state.ui.isMenuOpen
});
const mapDispatchToProps = {
  fetchMetrics
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
