import * as React from 'react';
import { Grid } from '@material-ui/core';
import FoodwasteTargetCard from 'pages/Settings/components/settings/goals/FoodwasteTargetCard';
import FrequencyCard from 'pages/Settings/components/settings/goals/FrequencyCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  grid: {
    maxWidth: 1600
  }
});

const Goals: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12} md={6}>
        <FrequencyCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <FrequencyCard goalType='guests' />
      </Grid>
      <Grid item xs={12} md={6}>
        <FoodwasteTargetCard />
      </Grid>
    </Grid>
  );
};

export default Goals;
