import theme from 'styles/themes/reports';
import createLockedSeries from 'pages/Report/components/Chart/utils/createLockedSeries';
import BaseOptions from 'pages/Report/components/Chart/Line/options';
import merge from 'lodash/merge';
import moment from 'moment';
import { SeriesMapper, ThemeMapper } from 'pages/Report/components/Chart/utils/getChartData';
import { PointData } from 'redux/ducks/reportData';
import { VACATION_COLOR } from 'pages/Report';

const seriesMappers: SeriesMapper[] = [
  (data) => ({
    type: 'line',
    data: data.series
      .slice(0, 1)
      .flatMap((s) => s.points)
      .map((point: PointData<{ onVacation: string[] }>) => ({
        marker: {
          fillColor:
            point.extra?.onVacation?.length > 0 ? VACATION_COLOR : theme.palette.common.white
        },
        y: point.value,
        x: moment.utc(point.label).valueOf(),
        custom:
          point.extra?.onVacation?.length > 0 ? { onVacation: point.extra.onVacation } : undefined
      })),
    marker: {
      lineColor: '#2196f4',
      radius: 4
    },
    name: data.intl.messages[
      `report.terms.${
        data.series[0].id === 'frequencyTotalGuestsPerDay'
          ? 'guestRegistrations'
          : 'foodwasteRegistrations'
      }`
    ]
  }),
  (data) =>
    createLockedSeries({
      type: 'line',
      color: theme.palette.grey.A100,
      name: data.intl.messages['report.terms.avgRegistrations'],
      dashStyle: 'Dash',
      data: Array.from({
        length: data.series.slice(0, 1).flatMap((s) => s.points).length
      }).map((_, i) => [
        moment.utc(data.series[0].points[i].label).valueOf(),
        data.series[0].aggregates.avg
      ])
    }),
  (data) =>
    createLockedSeries({
      type: 'line',
      data: Array.from({
        length: data.series.slice(0, 1).flatMap((s) => s.points).length
      }).map((_, i) => [
        moment.utc(data.series[0].points[i].label).valueOf(),
        data.series[0].aggregates.max
      ]),
      color: theme.palette.success.main,
      name: data.intl.messages['report.frequency.registrationsPerAccount.bestPerforming'],
      visible: false
    }),
  (data) =>
    createLockedSeries({
      type: 'line',
      data: Array.from({
        length: data.series.slice(0, 1).flatMap((s) => s.points).length
      }).map((_, i) => [
        moment.utc(data.series[0].points[i].label).valueOf(),
        data.series[0].aggregates.min
      ]),
      color: theme.palette.error.main,
      name: data.intl.messages['report.frequency.registrationsPerAccount.worstPerforming'],
      visible: false
    })
];

const themeMapper: ThemeMapper = (data) =>
  merge({}, BaseOptions, {
    tooltip: {
      pointFormatter: function () {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
        const base = `<div>${this.series.name}: ${this.y}</div>`;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const onVacation = this.custom?.onVacation as string[];
        if (onVacation) {
          return (
            base +
            `<br><br><b>${
              data.intl.messages['report.frequency.registrationsPerAccount.onVacation']
            }</b><br><ul>${onVacation.map((account) => '<li>' + account + '</li>').join('')}</ul>`
          );
        }
        return base;
      }
    }
  });

export { themeMapper, seriesMappers };
