import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  ApiRegistration,
  DataRegistrationActions,
  DataRegistrationActionTypes,
  DataRegistrationsState
} from './types';
import { ThunkResult } from 'redux/types';
import { API_DATE_FORMAT } from 'utils/datetime';

export * from './types';
export * from './selectors';

export interface IPatchObject {
  op: string;
  path: string;
  value: string | any | any[] | number;
}

const transfer = new DataTransfer({ retryConfig: { retries: 3 } });

export const initialState: DataRegistrationsState = {
  data: [],
  loading: false,
  failure: false
};

export default function reducer(
  state: DataRegistrationsState = initialState,
  action: DataRegistrationActions
): DataRegistrationsState {
  switch (action.type) {
    case DataRegistrationActionTypes.FIND_REQUEST:
    case DataRegistrationActionTypes.UPDATE_REQUEST: {
      return { ...state, loading: true };
    }
    case DataRegistrationActionTypes.FIND_FAILURE:
    case DataRegistrationActionTypes.UPDATE_FAILURE: {
      return { ...state, loading: false, failure: true };
    }
    case DataRegistrationActionTypes.FIND_SUCCESS: {
      return {
        ...state,
        loading: false,
        failure: false,
        data: action.payload
      };
    }
    case DataRegistrationActionTypes.UPDATE_SUCCESS: {
      const registration = action.payload;
      const data = state.data.map((r) =>
        r.id === registration.id ? { ...r, ...registration } : r
      );

      return {
        ...state,
        loading: false,
        failure: false,
        data
      };
    }
    case DataRegistrationActionTypes.REMOVE_SUCCESS: {
      const { payload: deletedId } = action;
      const data = state.data.filter((registration) => registration.id !== deletedId);
      return { ...state, data };
    }
    case DataRegistrationActionTypes.REMOVE_REQUEST:
    case DataRegistrationActionTypes.REMOVE_FAILURE:
    default: {
      return state;
    }
  }
}

export interface FetchRegistrationsOptions {
  date?: { from: string; to?: string };
  createdAt?: { from: string; to: string };
}

const createDefaultOptions = (options?: FetchRegistrationsOptions): FetchRegistrationsOptions => {
  // tricky, we require in minimum date range query params,
  // due to lack of pagination
  if (!options) {
    return {
      date: {
        from: moment().subtract(1, 'month').format(API_DATE_FORMAT),
        to: moment().format(API_DATE_FORMAT)
      }
    };
  }

  if (options.date && !options.date.to) {
    return {
      ...options,
      date: {
        ...options.date,
        to: moment().format(API_DATE_FORMAT)
      }
    };
  }

  return options;
};

const createQueryParams = (options: FetchRegistrationsOptions) => {
  const params = {};
  const { date, createdAt } = options;

  if (date) {
    params['startDate'] = date.from;
    params['endDate'] = date.to;
  }

  if (createdAt) {
    params['createdAt[$gte]'] = createdAt.from;
    params['createdAt[$lte]'] = createdAt.to;
  }

  return params;
};

export function fetchRegistrations(
  options?: FetchRegistrationsOptions
): ThunkResult<Promise<DataRegistrationActions>, DataRegistrationActions> {
  const params = createQueryParams(createDefaultOptions(options));

  return async (dispatch) => {
    const config = {
      params
    };

    try {
      const response = (await transfer.get('/foodwaste/registrations', config)) as AxiosResponse<
        ApiRegistration[]
      >;

      return dispatch({
        type: DataRegistrationActionTypes.FIND_SUCCESS,
        // todo: remove the mapping, unnecessary parseInt
        payload: response.data.map((registration) => ({
          ...registration,
          co2: parseInt(registration.co2),
          cost: parseInt(registration.cost)
        }))
      });
    } catch (err: unknown) {
      return dispatch({
        type: DataRegistrationActionTypes.FIND_FAILURE
      });
    }
  };
}

export function update(
  id: string,
  patchObject: IPatchObject[]
): ThunkResult<Promise<DataRegistrationActions>, DataRegistrationActions> {
  return async (dispatch) => {
    dispatch({
      type: DataRegistrationActionTypes.UPDATE_REQUEST
    });

    try {
      const response = (await transfer.patch(
        `/foodwaste/registrations/${id}`,
        patchObject
      )) as AxiosResponse<ApiRegistration>;
      return dispatch({
        type: DataRegistrationActionTypes.UPDATE_SUCCESS,
        payload: {
          ...response.data,
          co2: parseInt(response.data.co2),
          cost: parseInt(response.data.cost)
        }
      });
    } catch (err: unknown) {
      return dispatch({
        type: DataRegistrationActionTypes.UPDATE_FAILURE
      });
    }
  };
}

export function remove(
  id: string
): ThunkResult<Promise<DataRegistrationActions>, DataRegistrationActions> {
  return async (dispatch) => {
    dispatch({
      type: DataRegistrationActionTypes.REMOVE_REQUEST
    });

    try {
      await transfer.delete(`/foodwaste/registrations/${id}`, { params: {} });
      return dispatch({
        type: DataRegistrationActionTypes.REMOVE_SUCCESS,
        payload: id
      });
    } catch (err: unknown) {
      return dispatch({
        type: DataRegistrationActionTypes.REMOVE_FAILURE
      });
    }
  };
}
