import { UserActions, UserProfile, UserState, UserActionTypes, FoodwasteAccount } from './types';
import { ThunkResult } from 'redux/types';
import { ErrorActions } from 'redux/ducks/error';
import { DataTransfer, AxiosResponse } from 'frontend-core';
import * as errorDispatch from 'redux/ducks/error';
import { UiActions } from 'redux/ducks/ui';
import { SettingsState } from 'redux/ducks/settings';

export * from './types';

const transfer = new DataTransfer();

export const initialState: UserState = {
  email: '',
  name: '',
  client: '',
  subscriptionHistory: [],
  state: 'init'
};

export default function reducer(state: UserState = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.USER_LOADED: {
      const { profile, subscriptionHistory, subscription } = action.payload;
      return { ...state, ...profile, subscription, subscriptionHistory, state: 'fetched' };
    }
    case UserActionTypes.SET_CLIENT:
      return { ...state, client: action.payload };
    default:
      return state;
  }
}

export interface InitUserOptions {
  forceInit?: boolean;
}

export interface BootstrapUserData {
  templateId?: string;
  settings?: Partial<SettingsState>;
}

export function bootstrapUser(
  bootstrapData: BootstrapUserData = {}
): ThunkResult<Promise<UserActions | ErrorActions>, UserActions | UiActions | ErrorActions> {
  return async (dispatch) => {
    try {
      await transfer.post('/foodwaste/bootstrap-tasks', bootstrapData);
      window.location.reload();
    } catch (error) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function initUser(
  user: UserProfile,
  { forceInit }: InitUserOptions = {}
): ThunkResult<Promise<UserActions | ErrorActions>, UserActions | UiActions | ErrorActions> {
  return async (dispatch, getState) => {
    const { state } = getState().user;

    if (state !== 'init' && !forceInit) {
      return;
    }

    try {
      const response = (await transfer.get('foodwaste/account')) as AxiosResponse<FoodwasteAccount>;
      const {
        data: { settings, subscription, subscriptionHistory, subAccounts }
      } = response;

      return dispatch({
        type: UserActionTypes.USER_LOADED,
        payload: {
          profile: { ...user, customerName: user.company },
          settings,
          subscription,
          subscriptionHistory,
          subAccounts
        }
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function setClient(client: string): UserActions {
  return {
    type: UserActionTypes.SET_CLIENT,
    payload: client
  };
}
