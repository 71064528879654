import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import { FrequencyTarget, getSettings, OverrideSettings } from 'redux/ducks/settings';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel
} from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { update as updateSettings } from 'redux/ducks/settings';
import SelectPeriodModal from 'pages/Settings/components/settings/goals/SelectPeriodModal';
import CardHeader from 'pages/Settings/components/settings/goals/CardHeader';
import { API_DATE_FORMAT } from 'utils/datetime';
import HelpText from 'components/HelpText';
import LockIcon from '@material-ui/icons/Lock';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type OwnProps = {
  goalType?: 'waste' | 'guests';
};
type GoalsProps = StateProps & DispatchProps & InjectedIntlProps & OwnProps;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap'
  },
  content: {
    flexGrow: 1
  },
  dayList: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    maxWidth: '600px'
  },
  dayGroup: {
    display: 'flex',
    flexFlow: 'column wrap'
  },
  dayGroupMiddle: {
    [theme.breakpoints.down(500)]: {
      marginRight: '100%',
      order: 2
    }
  },
  cardActions: {
    '& > :last-child': { marginLeft: 'auto' }
  },
  formLabel: {
    fontWeight: 600
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  },
  lockBox: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const dayGroups = [
  [1, 2, 3],
  [4, 5],
  [6, 0]
];

const days = [1, 2, 3, 4, 5, 6, 0];
const defaultFrequency: FrequencyTarget = { days: [] };

const frequencyToDOW = (
  frequency: FrequencyTarget = defaultFrequency
): { [dow: number]: boolean } =>
  days.reduce(
    (all, current) => ({
      ...all,

      [current]: frequency.days.some((day) => day === current)
    }),
    {}
  );

const FrequencyCard: React.FunctionComponent<GoalsProps> = (props) => {
  const classes = useStyles(props);
  const {
    frequency,
    frequencyHistory,
    guestFrequency,
    guestFrequencyHistory,
    intl,
    updateSettings,
    overrideChildSettings,
    hasSubscribedAccounts,
    lockedByAdmin,
    goalType = 'waste'
  } = props;

  const isGuestFrequencyType = goalType === 'guests';
  const [frequencyDraft, setFrequencyDraft] = React.useState(
    frequencyToDOW(isGuestFrequencyType ? guestFrequency : frequency)
  );
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [overrideState, setOverrideState] = React.useState<undefined | 'show' | 'loading'>();

  const hasChanged = React.useCallback(() => {
    const freqDow = frequencyToDOW(isGuestFrequencyType ? guestFrequency : frequency);
    return Object.keys(frequencyDraft).some((dow) => freqDow[dow] !== frequencyDraft[dow]);
  }, [frequency, guestFrequency, frequencyDraft]);

  React.useEffect(() => {
    setFrequencyDraft(frequencyToDOW(isGuestFrequencyType ? guestFrequency : frequency));
  }, [frequency, guestFrequency]);

  React.useEffect(() => {
    if (overrideState) {
      setOverrideState(undefined);
    }
  }, [overrideChildSettings]);

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: dow } = e.target;
    setFrequencyDraft((prev) => ({ ...prev, [dow]: !prev[dow] }));
  };

  const handleUndo = () => {
    setFrequencyDraft(frequencyToDOW(frequency));
  };

  // common logic, could be extracted to an util
  const handleSave = (overridePrevious: boolean) => {
    const nextFrequency = Object.keys(frequencyDraft)
      .filter((day) => frequencyDraft[day])
      .map((day) => parseInt(day));
    if (overridePrevious) {
      const from = moment(new Date(0)).format(API_DATE_FORMAT);
      const settingsToUpdate = isGuestFrequencyType
        ? { expectedGuestFrequency: [{ from, days: nextFrequency }] }
        : { expectedFrequency: [{ from, days: nextFrequency }] };
      updateSettings(settingsToUpdate);
    } else {
      const from = moment().format('YYYY-MM-DD');
      const settingsToUpdate = isGuestFrequencyType
        ? {
            expectedGuestFrequency: [
              ...guestFrequencyHistory.filter((slot) => slot.from !== from),
              { from, days: nextFrequency }
            ]
          }
        : {
            expectedFrequency: [
              ...frequencyHistory.filter((slot) => slot.from !== from),
              { from, days: nextFrequency }
            ]
          };
      updateSettings(settingsToUpdate);
    }
    setShowModal(false);
  };

  const handleOverride = (overridePrevious: boolean) => {
    setOverrideState('loading');
    const overrideSettings = isGuestFrequencyType
      ? {
          expectedGuestFrequency: {
            enabled: true,
            overridePrevious
          }
        }
      : {
          expectedFrequency: {
            enabled: true,
            overridePrevious
          }
        };
    const nextOverrideChildSettings = {
      ...overrideChildSettings,
      ...overrideSettings
    } as OverrideSettings;

    updateSettings({
      overrideChildSettings: nextOverrideChildSettings
    });
  };

  const handleSelectPeriod = () => {
    setShowModal(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardHeader
          title={
            intl.messages[
              `benchmarks.${
                isGuestFrequencyType ? 'guestFrequencyRegistrations' : 'frequencyRegistrations'
              }`
            ]
          }
          titleHelpIcon={
            <HelpText
              helpText={
                intl.messages[
                  `benchmarks.${
                    isGuestFrequencyType ? 'guestFrequencyRegistrations' : 'frequencyRegistrations'
                  }.description`
                ]
              }
            />
          }
        />
        <div className={classes.dayList}>
          {dayGroups.map((days, index) => (
            <div
              key={`day_group_${index}`}
              className={classNames(classes.dayGroup, { [classes.dayGroupMiddle]: index === 1 })}
            >
              {days.map((day) => (
                <FormControlLabel
                  key={`s_day_${day}`}
                  control={
                    <Checkbox
                      disabled={
                        isGuestFrequencyType
                          ? lockedByAdmin.expectedGuestFrequency
                          : lockedByAdmin.expectedFrequency
                      }
                      color='primary'
                      name={`day-${day}`}
                      value={day}
                      checked={frequencyDraft[day]}
                      onChange={handleFrequencyChange}
                    />
                  }
                  label={moment.weekdays(day)}
                />
              ))}
            </div>
          ))}
        </div>
      </CardContent>
      <CardActions
        className={classNames({
          [classes.cardActions]:
            (isGuestFrequencyType
              ? lockedByAdmin.expectedGuestFrequency
              : lockedByAdmin.expectedFrequency) || hasSubscribedAccounts
        })}
      >
        <Button
          variant='text'
          onClick={handleUndo}
          disabled={
            (isGuestFrequencyType
              ? lockedByAdmin.expectedGuestFrequency
              : lockedByAdmin.expectedFrequency) || !hasChanged()
          }
        >
          {intl.messages['base.undo']}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSelectPeriod}
          disabled={
            (isGuestFrequencyType
              ? lockedByAdmin.expectedGuestFrequency
              : lockedByAdmin.expectedFrequency) || !hasChanged()
          }
        >
          {intl.messages['base.save']}
        </Button>
        {isGuestFrequencyType
          ? lockedByAdmin.expectedGuestFrequency
          : lockedByAdmin.expectedFrequency && (
              <Chip
                variant='outlined'
                label={intl.messages['settings.targets.locked']}
                icon={<LockIcon fontSize='small' />}
              />
            )}
        {hasSubscribedAccounts && (
          <Button
            disabled={hasChanged()}
            color='primary'
            variant='contained'
            onClick={() => setOverrideState('show')}
          >
            {intl.messages['settings.targets.applyToSubaccounts']}
          </Button>
        )}
      </CardActions>
      <SelectPeriodModal
        title={
          intl.messages[
            `benchmarks.${
              isGuestFrequencyType ? 'guestFrequencyRegistrations' : 'frequencyRegistrations'
            }`
          ]
        }
        content={intl.messages['dashboard.widget.settings.applySettingsForPreviousPeriods.msg']}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onAccept={() => {
          handleSave(true);
        }}
        onDecline={() => {
          handleSave(false);
        }}
      />
      <SelectPeriodModal
        title={
          intl.messages[
            `settings.targets.modal.${
              isGuestFrequencyType ? 'overrideGuestFrequency' : 'overrideFrequency'
            }.title`
          ]
        }
        content={intl.messages['settings.targets.modal.overrideContent']}
        open={!!overrideState}
        isLoading={overrideState === 'loading'}
        onCancel={() => setOverrideState(undefined)}
        onAccept={() => {
          handleOverride(true);
        }}
        onDecline={() => {
          handleOverride(false);
        }}
      />
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  frequency: getSettings(state).currentExpectedFrequency,
  frequencyHistory: getSettings(state).expectedFrequency || [],
  guestFrequency: getSettings(state).currentExpectedGuestFrequency,
  guestFrequencyHistory: getSettings(state).expectedGuestFrequency || [],
  overrideChildSettings: state.settings.overrideChildSettings || {},
  hasSubscribedAccounts: state.subAccounts.subscribed.length !== 0,
  lockedByAdmin: getSettings(state).lockedByAdmin
});

const mapDispatchToProps = {
  updateSettings
};
export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FrequencyCard));
