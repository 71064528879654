import Highcharts from 'highcharts';

function createLockedSeries(options: Highcharts.SeriesLineOptions): Highcharts.SeriesOptions {
  return {
    ...options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tooltip: {
      headerFormat: '',
      ...options.tooltip
    },
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false
        }
      }
    }
  };
}

export default createLockedSeries;
