import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import rootReducer, { RootState } from 'redux/rootReducer';
import { syncHistoryWithStore } from 'react-router-redux';
import ReduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';
import { browserHistory } from 'browserHistory';
import {
  AuthClient,
  AuthProvider,
  AuthInitOptions,
  parseTokensFromSearchParams,
  DataTransfer,
  NetworkStatus,
  NetworkStatusProvider
} from 'frontend-core';
import theme from 'styles/themes/global';
import { ThemeProvider } from '@material-ui/styles';
import { isScaleApp } from 'utils/scale';
import { Provider } from 'react-redux';
import FlagProvider from 'components/FlagsProvider';
import ConnectedIntlProvider from 'components/ConnectedIntlProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Router } from 'react-router';
import rootRoutes from 'routes';
import { Bootstrap } from 'components/Bootstrapper';
import { SnackbarProvider } from 'notistack';
import { ServiceWorkerProvider } from 'context/ServiceWorkerContext';

const middlewares = [ReduxThunk, ReduxPromise];
const isScale = isScaleApp();

const initialState: Partial<RootState> = {};

if (isScale) {
  initialState['user'] = {
    state: 'init',
    client: 'scale'
  };
}

const store = createStore(
  rootReducer,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  initialState,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  composeWithDevTools(applyMiddleware(...middlewares))
);

const routerHistory = syncHistoryWithStore(browserHistory, store);

const { AUTH_DOMAIN, AUTH_CLIENT, AUTH_SCALE_CLIENT, AUTH_URL, API_URL } = window.sysvars || {};

const authInitOptions: AuthInitOptions = {
  // requireLogin false due to offline support
  requireLogin: false,
  enableLogging: process.env.NODE_ENV === 'development',
  scope: 'foodwaste-api',
  redirectURL: `${location.origin}${ASSET_PATH}registration`,
  publicPath: `${location.origin}${ASSET_PATH}`,
  enableLegacyLogout: true,
  ...parseTokensFromSearchParams()
};

// if there is no service worker, it's pointless to use network status,
// since offline relies on service worker.
if (!('serviceWorker' in navigator) || !navigator.serviceWorker.controller) {
  NetworkStatus.disable();
}

const authClient = AuthClient('keycloak', {
  domain: AUTH_DOMAIN,
  clientId: isScale ? AUTH_SCALE_CLIENT : AUTH_CLIENT,
  authServerURL: AUTH_URL
});

authClient.addEventListener('onTokenExpired', () => void authClient.getAccessToken());
authClient.addEventListener(
  'onRefreshError',
  () => void authClient.logout({ locale: store.getState().settings.locale })
);

DataTransfer.setAuthenticationHandler(async () => {
  if (authClient.isAuthenticated()) {
    return authClient.getAccessToken();
  }
});

const SNACKBAR_ORIGIN = { vertical: 'top', horizontal: 'right' } as const;
const SNACKBAR_STYLE = { width: '320px', flexWrap: 'nowrap' } as const; // to avoid toasts with different widths, looks weird

// todo: ping_url env variable
NetworkStatus.changePing({ url: new URL('/foodwaste/status', API_URL).toString() });
NetworkStatus.addTrigger('activity', { throttleInMillis: 10000 });

const render = () => {
  ReactDOM.render(
    <NetworkStatusProvider>
      <ServiceWorkerProvider>
        <AuthProvider clientInstance={authClient} initOptions={authInitOptions}>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <FlagProvider>
                <ConnectedIntlProvider>
                  <Bootstrap>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <SnackbarProvider
                        autoHideDuration={3000}
                        anchorOrigin={SNACKBAR_ORIGIN}
                        style={SNACKBAR_STYLE}
                      >
                        <Router history={routerHistory} routes={rootRoutes} />
                      </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                  </Bootstrap>
                </ConnectedIntlProvider>
              </FlagProvider>
            </Provider>
          </ThemeProvider>
        </AuthProvider>
      </ServiceWorkerProvider>
    </NetworkStatusProvider>,
    document.getElementById('react-app')
  );
};

// ping before render to make sure we are online,
// should move to bootstrap logic
void NetworkStatus.ping().finally(render);

// Pressing "ENTER" will either cause form submission or tabbing to next input field
document.body.addEventListener('keypress', function (e: HTMLElementEventMap['keypress']) {
  if (
    e.key !== 'Enter' ||
    e.shiftKey ||
    (e.target && e.target instanceof HTMLTextAreaElement) ||
    !(e.target as HTMLFormElement).form
  )
    return;

  let lastElement;
  // ts seems to be missing form submit event
  const form = (e.target as HTMLElement & { form: HTMLFormElement }).form;

  if (!form.dataset.shouldSubmitOnEnter) {
    for (let i = form.length - 1; i >= 0; i--) {
      const element = form[i] as HTMLFieldSetElement;
      if ((element.type != 'button' && element.type != 'submit') || element.dataset.inputBtn) {
        lastElement = form[i];
        break;
      }
    }
  }
  if (lastElement === e.target || form.dataset.shouldSubmitOnEnter) {
    for (let y = 0; y < form.length; y++) {
      const element = form[y] as HTMLFieldSetElement;
      if (element.type == 'submit') {
        element.click();
        e.preventDefault();
        return;
      }
    }

    form.dispatchEvent(new Event('submit'));
    e.preventDefault();
  } else {
    for (let i = 0; i < form.length - 1; i++) {
      if (form[i] === e.target) {
        for (let y = i + 1; y < form.length; y++) {
          const element = form[y] as HTMLFieldSetElement;
          if (
            (element.type != 'button' && element.type != 'submit') ||
            (element.className != 'helpIconBtn' && element.className != 'removeBtn')
          ) {
            element.focus();
            e.preventDefault();
            break;
          } else if (element.dataset.inputBtn) {
            element.click();
            e.preventDefault();
            break;
          }
        }
        break;
      }
    }
  }
});
