import { createSelectorCreator, defaultMemoize } from 'reselect';
import { RootState } from 'redux/rootReducer';
import { SettingsState, StartSlot } from 'redux/ducks/settings/types';
import isEqual from 'lodash/isEqual';
import { API_DATE_FORMAT } from 'utils/datetime';
import moment from 'moment/moment';
import * as momentTimezone from 'moment-timezone';

// depracated
function parseCurrentFromHistory<T>(settings?: { [date: string]: T }): T | undefined {
  if (!settings) {
    return undefined;
  }

  const dates = Object.keys(settings);

  if (dates.length === 0) {
    return undefined;
  }

  const latestDate = dates[dates.length - 1];
  return settings[latestDate];
}

function parseCurrentFromTimeSlots<T extends StartSlot>(timeSlots?: T[]): T | undefined {
  if (!timeSlots || timeSlots.length === 0) {
    return undefined;
  }

  const sortDescByStart = (a: T, b: T) =>
    moment(b.from, API_DATE_FORMAT).valueOf() - moment(a.from, API_DATE_FORMAT).valueOf();

  return [...timeSlots].sort(sortDescByStart)[0];
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const settingsSelector = (state: RootState): SettingsState => state.settings;

const getHasGuestTypesEnabled = createDeepEqualSelector(
  settingsSelector,
  (settings: SettingsState) => settings.guestTypes && settings.guestTypes.enabled
);

const getSavedFilterSelections = createDeepEqualSelector(
  settingsSelector,
  (settings) => settings.savedFilterSelections || []
);

const getSettings = createDeepEqualSelector(settingsSelector, (settings) => ({
  ...settings,
  activeRegistrationsFrequency: parseCurrentFromHistory(settings.registrationsFrequency) || [],
  activeExpectedWeeklyWaste: parseCurrentFromHistory(settings.expectedWeeklyWaste),
  currentExpectedFoodWaste: parseCurrentFromTimeSlots(settings.expectedFoodwaste),
  currentExpectedFoodWastePerGuest: parseCurrentFromTimeSlots(settings.expectedFoodwastePerGuest),
  currentExpectedFrequency: parseCurrentFromTimeSlots(settings.expectedFrequency),
  currentExpectedGuestFrequency: parseCurrentFromTimeSlots(settings.expectedGuestFrequency),
  currentPerGuestBaseline: parseCurrentFromTimeSlots(settings.perGuestBaseline),
  currentPerGuestStandard: parseCurrentFromTimeSlots(settings.perGuestStandard),
  overrideChildSettings: settings.overrideChildSettings || {},
  lockedByAdmin: settings.lockedByAdmin || {}
}));

const getAlarms = createDeepEqualSelector(settingsSelector, (settings) =>
  settings.alarms
    ? settings.alarms
    : {
        enabled: false,
        message: '',
        executionTime: '0',
        zone: momentTimezone.tz.guess(),
        recipients: []
      }
);

export { getHasGuestTypesEnabled, getSavedFilterSelections, getSettings, getAlarms };
