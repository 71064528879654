import * as React from 'react';
import { injectIntl, InjectedIntlProps, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Card, CardContent, Grid } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { fetchMetrics } from 'redux/ducks/dashboard';
import { connect } from 'react-redux';
import {
  getReportDashboardMetrics,
  getSelectedAccountIds,
  getTimeFilter
} from 'redux/ducks/reportFilter/selectors';
import {
  DASHBOARD_METRIC_INTL_KEYS,
  DEFAULT_DASHBOARD_METRIC_OPTIONS,
  getStatusIntlKey
} from 'pages/Dashboard/DashboardMetricCard';
import Fact from 'pages/Report/components/Fact';
import { formatNumber, FormatNumberOptions, MassUnit, transformAmount } from 'utils/number-format';
import { ProgressText } from 'pages/Report/components/ProgressMessage';
import { makeStyles } from '@material-ui/styles';
import MealIndicator from 'pages/Dashboard/MealIndicator';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type CarbonFootprintProps = StateProps & DispatchProps & InjectedIntlProps;

const valueFormatOptions: FormatNumberOptions = {
  displayUnit: true,
  precision: 0,
  rounding: 'round'
};

const useStyles = makeStyles({
  fullHeight: {
    height: '100%'
  }
});

const DashboardMetrics: React.FunctionComponent<CarbonFootprintProps> = (props) => {
  const classes = useStyles(props);
  const {
    timeFilter: { period, from, to },
    metrics,
    customerIds,
    fetchMetrics,
    state,
    dimension
  } = props;

  React.useEffect(() => {
    if (customerIds) {
      void fetchMetrics({ customerIds, timeRange: { from, to }, period, dimension });
    }
  }, [customerIds, period, from, to, dimension]);

  return (
    <>
      {metrics.map((metric) => {
        const { id, point, status, growth, isTrendInverted } = metric;
        const intlKeys = DASHBOARD_METRIC_INTL_KEYS[id];
        const { as, indicator } = DEFAULT_DASHBOARD_METRIC_OPTIONS[id];
        const transformOptions = as ? { unit: point.unit, as } : undefined;
        const viewUnit = as || (point.unit as MassUnit);

        return (
          <Grid key={metric.id} item xs={12} sm={6} lg={3}>
            <Card className={classes.fullHeight}>
              <CardContent>
                <Fact
                  isTrendInverted={isTrendInverted}
                  isLoading={state !== 'idle'}
                  value={formatNumber(transformAmount(point.value, transformOptions), {
                    ...valueFormatOptions,
                    unit: viewUnit
                  })}
                  title={<FormattedHTMLMessage id={intlKeys.title} values={{ value: '' }} />}
                  message={{
                    customIcon: indicator === MealIndicator ? <MealIndicator /> : undefined,
                    text: (
                      <FormattedMessage
                        id={getStatusIntlKey(metric)}
                        values={{
                          value: (
                            <ProgressText>
                              {formatNumber(status.value, {
                                ...valueFormatOptions,
                                signDisplay: 'never',
                                unit: status.unit
                              })}
                            </ProgressText>
                          )
                        }}
                      />
                    ),
                    progressValue: metric.growth === 'noTrend' ? 0 : status.value,
                    invertedProgress:
                      (status.value < 0 && growth === 'positive') ||
                      (status.value > 0 && growth === 'negative')
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  state: state.dashboard.state,
  metrics: getReportDashboardMetrics(state),
  timeFilter: getTimeFilter(state),
  customerIds: getSelectedAccountIds(state),
  dimension: state.reportFilter.dimension
});

const mapDispatchToProps = {
  fetchMetrics
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardMetrics));
