import { DataStorage, DataTransfer, AxiosResponse } from 'frontend-core';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { Modal, Module, ModuleLink, UiActions, UiActionTypes, UiState } from './types';
import { ThunkResult } from 'redux/types';
import { RootState } from 'redux/rootReducer';
import { ErrorActions, displayError } from 'redux/ducks/error';

export * from './types';

const storage = new DataStorage();
const isMenuPinned = getMenuPinnedFlag();

const isGoNative = window.navigator.userAgent.includes('esm');

type ReturnToKeys = 'haccp';
type ReturnToFilterTypes = {
  [key in ReturnToKeys]: string;
};

const returnToQueryToModuleUrlFilter: ReturnToFilterTypes = {
  haccp: 'legacyMenuItemId=normal'
};

function getMenuPinnedFlag(): boolean {
  if (!storage) {
    return true;
  }

  const isPinnedFlag = storage.getData('isMenuPinned') as number | undefined;

  return isPinnedFlag !== 0;
}

function getMenuOpenFlag(isMenuPinned: boolean): boolean {
  if (!window.matchMedia) {
    return false;
  }

  const mobileScreenQuery = window.matchMedia('(max-width: 767px)');
  return mobileScreenQuery.matches ? false : isMenuPinned;
}

export const initialState: UiState = {
  isMenuOpen: getMenuOpenFlag(isMenuPinned),
  modal: {
    visible: null,
    title: null,
    content: null,
    className: null,
    fullBleed: null
  },
  modules: [],
  pages: {
    report: {
      currentTab: 0
    }
  },
  isMenuPinned: isMenuPinned,
  credentials: {
    dealNumber: '',
    username: '',
    password: ''
  },
  introWizardDisplayed:
    (storage.getData('introWizardDisplayed') as { [accountId: string]: boolean }) || {},
  isScreenLocked: false,
  returnTo: ''
};

const transfer = new DataTransfer();

export default function reducer(state: UiState = initialState, action: UiActions): UiState {
  switch (action.type) {
    case UiActionTypes.SHOW_MENU: {
      return { ...state, isMenuOpen: true };
    }

    case UiActionTypes.HIDE_MENU: {
      return { ...state, isMenuOpen: false };
    }

    case UiActionTypes.TOGGLE_MENU: {
      return { ...state, isMenuOpen: !state.isMenuOpen };
    }

    case UiActionTypes.TOGGLE_MENU_PINNING: {
      return { ...state, isMenuPinned: action.payload };
    }

    case UiActionTypes.SHOW_MODAL: {
      return { ...state, modal: { ...action.payload, visible: true } };
    }

    case UiActionTypes.HIDE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: false,
          content: null
        }
      };
    }

    case UiActionTypes.GET_SELECTOR_ITEMS: {
      return { ...state, modules: action.payload };
    }

    case UiActionTypes.CLEAR_SELECTOR_ITEMS: {
      return { ...state, modules: initialState.modules };
    }

    case UiActionTypes.TOGGLE_SCREEN_LOCK: {
      return { ...state, isScreenLocked: action.payload };
    }

    case UiActionTypes.SET_BACK_TO_HACCP: {
      return { ...state, returnTo: action.payload };
    }

    default:
      return state;
  }
}

export function showMenu(): UiActions {
  return {
    type: UiActionTypes.SHOW_MENU
  };
}

export function hideMenu(): UiActions {
  return {
    type: UiActionTypes.HIDE_MENU
  };
}

export function toggleMenu(): UiActions {
  return {
    type: UiActionTypes.TOGGLE_MENU
  };
}

export function showModal(modal: Modal): UiActions {
  return {
    type: UiActionTypes.SHOW_MODAL,
    payload: modal
  };
}

export function hideModal(): UiActions {
  return {
    type: UiActionTypes.HIDE_MODAL
  };
}

export function toggleMenuPinning(): UiActions {
  if (storage.getData('isMenuPinned') === 0) {
    storage.setData('isMenuPinned', '1');
  } else {
    // todo fix once setData fixed to accept any data type
    storage.setData('isMenuPinned', '0');
  }

  return {
    type: UiActionTypes.TOGGLE_MENU_PINNING,
    payload: !!(storage.getData('isMenuPinned') as number)
  };
}

export function clearSelectorItems(): UiActions {
  return {
    type: UiActionTypes.CLEAR_SELECTOR_ITEMS
  };
}

export function toggleScreenLock(locked: boolean): UiActions {
  return {
    type: UiActionTypes.TOGGLE_SCREEN_LOCK,
    payload: locked
  };
}

export async function redirectToModule(module: ModuleLink): Promise<void> {
  const baseURL = window.sysvars.API_URL;

  // IF the user is gonative -> open the gonative haccp app (esm4safe)
  if (isGoNative === true && module.app === 'haccp_tablet') {
    window.location.replace('https://secure.e-smiley.dk/tablet/index.php');
  } else {
    // hack fix; while we dont have SS0 (via keycloak integration)
    // we need to deal with these legacy tokens as query params.
    // generated at foodwaste api
    const { data: urlWithToken } = (await transfer.get(`/foodwaste/modules/${module.id}`, {
      baseURL
    })) as AxiosResponse<string>;

    window.location.assign(urlWithToken);
  }
}

export function getModuleSelectorItems(): ThunkResult<
  Promise<UiActions | ErrorActions>,
  UiActions | ErrorActions
> {
  return async (dispatch, getState) => {
    try {
      let locale = getState().settings.locale;

      switch (locale) {
        case 'de':
          locale = 'ge';
          break;
      }

      const baseURL = window.sysvars.API_URL;

      const response = (await transfer.get(`/foodwaste/modules?locale=${locale}`, {
        baseURL
      })) as AxiosResponse<Module[]>;

      const searchParams = new URLSearchParams(window.location.search);
      const returnToModule = searchParams.get('return_to') as ReturnToKeys;

      const moduleUrlFilter = returnToModule
        ? response.data.find((module) =>
            module.url.includes(returnToQueryToModuleUrlFilter[returnToModule])
          )
        : '';

      if (moduleUrlFilter) {
        dispatch({
          type: UiActionTypes.SET_BACK_TO_HACCP,
          payload: returnToModule
        });
      }

      const modules = response.data.map((item) => ({
        ...item,
        url: `${baseURL}foodwaste/modules/${item.id}`,
        app: findAppFromUrl(item.url)
      }));

      return dispatch({
        type: UiActionTypes.GET_SELECTOR_ITEMS,
        payload: isGoNative ? modules.filter((module) => module.app === 'haccp_tablet') : modules
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

const findAppFromUrl = (url: string) => {
  if (url.includes('legacyMenuItemId=normal')) {
    return 'haccp_pc';
  }
  if (url.includes('legacyMenuItemId=tablet')) {
    return 'haccp_tablet';
  }
  return '';
};

export function lockScreenForDuration(
  durationInMs: number
): ThunkResult<Promise<UiActions>, UiActions> {
  return async (dispatch) => {
    const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    dispatch(toggleScreenLock(true));
    await delay(durationInMs);
    return dispatch(toggleScreenLock(false));
  };
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getModuleLinks = createDeepEqualSelector(
  (state: RootState) => state.ui.modules,
  (modules: Module[]): ModuleLink[] => modules.map((module) => mapModuleToModuleLink(module))
);

const mapModuleToModuleLink = (module: Module): ModuleLink => {
  return {
    id: module.id,
    label: module.name,
    link: `${module.url}`,
    icon: module.icon,
    app: module.app
  };
};

export const getReturnToModule = createDeepEqualSelector(
  (state: RootState) => state.ui.modules,
  (state: RootState) => state.ui.returnTo,
  (modules: Module[], returnTo): ModuleLink => {
    if (returnTo !== 'haccp') {
      return;
    }

    const module: Module = modules.find((module) => module.app === 'haccp_tablet');

    if (module) {
      return mapModuleToModuleLink(module);
    } else {
      return undefined;
    }
  }
);
