import { DashboardMetricId } from 'redux/ducks/dashboard/types';
import { RootState } from 'redux/rootReducer';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const dashboardMetricIds: Set<DashboardMetricId> = new Set([
  'registration_frequency',
  'registration_guest_frequency',
  'per_guest_waste',
  'co2_waste',
  'per_guest_saved',
  'per_guest_avoidable'
]);

export const getDashboardMetrics = createDeepEqualSelector(
  (state: RootState) => state.dashboard.metrics,
  (metrics) => metrics.filter((metric) => dashboardMetricIds.has(metric.id))
);
