import { RegistrationPoint } from 'redux/ducks/data/registrationPoints/types';
import { FunFact } from 'redux/ducks/registration';
import { RegistrationAvoidable } from 'redux/ducks/registration';

export interface ApiRegistration {
  amount: number; // 12000
  comment?: string;
  cost: string; // "1476"
  co2: string;
  createdAt: string; // "2017-10-12 07:14:57"
  updatedAt?: string;
  accountId: string; // "24767"
  date: string; // "2017-10-12"
  userId: string; // "71171"
  id: string; // "5311"
  registrationPoint: RegistrationPoint;
  unit: string;
  currency: string;
  scale?: boolean;
  avoidable?: RegistrationAvoidable;
}

export interface Registration {
  amount: number; // 12000
  comment?: string;
  cost: number; // "1476"
  co2: number;
  createdAt: string; // "201
  updatedAt?: string;
  accountId: string;
  date: string; // "2017-10-12"
  userId: string;
  id: string;
  registrationPoint: RegistrationPoint;
  unit: string;
  currency: string;
  scale?: boolean;
  reasonId?: string;
  funFact?: FunFact;
  avoidable?: RegistrationAvoidable;
  offlineAt?: string;
  offline?: boolean; // ui only
}

export interface DataRegistrationsState {
  data: Registration[];
  loading: boolean;
  failure: boolean;
}

export enum DataRegistrationActionTypes {
  FIND_REQUEST = 'data/registrations/FIND_REQUEST',
  FIND_SUCCESS = 'data/registrations/FIND_SUCCESS',
  FIND_FAILURE = 'data/registrations/FIND_FAILURE',
  REMOVE_REQUEST = 'data/registrations/REMOVE_REQUEST',
  REMOVE_SUCCESS = 'data/registrations/REMOVE_SUCCESS',
  REMOVE_FAILURE = 'data/registrations/REMOVE_FAILURE',
  UPDATE_REQUEST = 'data/registrations/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'data/registrations/UPDATE_SUCCESS',
  UPDATE_FAILURE = 'data/registrations/UPDATE_FAILURE'
}

type RemoveRegistrationRequestAction = {
  type: typeof DataRegistrationActionTypes.REMOVE_REQUEST;
};

export type RemoveRegistrationSuccessAction = {
  type: typeof DataRegistrationActionTypes.REMOVE_SUCCESS;
  payload: string;
};

type RemoveRegistrationFailure = {
  type: typeof DataRegistrationActionTypes.REMOVE_FAILURE;
};

type UpdateRegistrationRequestAction = {
  type: typeof DataRegistrationActionTypes.UPDATE_REQUEST;
};

type UpdateRegistrationSuccessAction = {
  type: typeof DataRegistrationActionTypes.UPDATE_SUCCESS;
  payload: Registration;
};

type UpdateRegistrationFailure = {
  type: typeof DataRegistrationActionTypes.UPDATE_FAILURE;
};

type FindRegistrationRequestAction = {
  type: typeof DataRegistrationActionTypes.FIND_REQUEST;
};

type FindRegistrationSuccessAction = {
  type: typeof DataRegistrationActionTypes.FIND_SUCCESS;
  payload: Registration[];
};

type FindRegistrationFailureAction = {
  type: typeof DataRegistrationActionTypes.FIND_FAILURE;
};

export type DataRegistrationActions =
  | RemoveRegistrationRequestAction
  | RemoveRegistrationSuccessAction
  | RemoveRegistrationFailure
  | FindRegistrationRequestAction
  | FindRegistrationSuccessAction
  | FindRegistrationFailureAction
  | UpdateRegistrationRequestAction
  | UpdateRegistrationSuccessAction
  | UpdateRegistrationFailure;
